.pre-last-section {
  .addresses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.last-section {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 767px) {
    display: block;

    .socials {
      width: 100%;
      padding: 16px;
      justify-content: flex-end;
    }
  }
}
