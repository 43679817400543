.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (max-width: 767px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}
